import { FC, SyntheticEvent, useCallback, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import { IBannerResponse } from "@finbackoffice/clientbff-client";
import { SiteContentScopes, HrefBehavior } from "@finbackoffice/enums";
import { AuthContext, ModalsContext, useRuntimeConfig } from "@finbackoffice/site-core";
import { PROVIDERS_LOGO_MAP } from "@finbackoffice/fe-core";
import classNames from "classnames";
import Link from "next/link";
import Img, { ImgProps } from "components/base/img/Img";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import { useCasinoMethods } from "hooks";
import Video from "../video/Video";
import { CasinoGamePlayButtons } from "components/pages/casino/game-item/layouts/compact/Compact";
import Button from "../button/Button";
import styles from "./banners-slider.module.sass";

type Props = {
    banners: IBannerResponse[];
    sliderSettings?: Settings;
    imgProps: Partial<ImgProps>;
    wrapperClassName?: string;
    showOverlay?: boolean;
    casinoGamePromo?: { forFunSupport: boolean };
};

const BannersSlider: FC<Props> = ({
    banners,
    sliderSettings,
    imgProps,
    wrapperClassName,
    showOverlay,
    casinoGamePromo,
}) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { loginModalRef } = useContext(ModalsContext);
    const { handlePlayNow, handlePlayForFun } = useCasinoMethods(loginModalRef);
    const { isUserLoggedIn } = useContext(AuthContext);
    const defaultSettings: Settings = useMemo(
        () => ({
            dots: false,
            infinite: banners.length > 1,
            autoplay: banners.length > 1,
            arrows: banners.length > 1,
            autoplaySpeed: COMMON_SITE_CONFIGS.banners.sliderSpeed,
            speed: COMMON_SITE_CONFIGS.banners.transitionSpeed,
            slidesToShow: 1,
            slidesToScroll: 1,
            ...sliderSettings,
        }),
        [
            COMMON_SITE_CONFIGS.banners.sliderSpeed,
            COMMON_SITE_CONFIGS.banners.transitionSpeed,
            banners.length,
            sliderSettings,
        ],
    );

    const isGame = useCallback(
        (href_behavior: HrefBehavior) =>
            [HrefBehavior.GameSlot, HrefBehavior.GameLiveCasino].includes(href_behavior),
        [],
    );

    const isLink = useCallback(
        (href_behavior: HrefBehavior) =>
            [HrefBehavior.External, HrefBehavior.Relative].includes(href_behavior),
        [],
    );

    const renderMedia = useCallback(
        (banner: IBannerResponse, priority: boolean) => {
            const src = banner.translations[0].bg_media?.url ?? "";
            const alt = banner.translations[0].title;

            if (!!src) {
                if (banner.tags.includes(SiteContentScopes.VFX)) {
                    const { width, height } = imgProps ?? {};
                    return <Video src={src} width={width} height={height} />;
                }
                return <Img source={src} alt={alt} priority={priority} {...imgProps} />;
            }

            return null;
        },
        [imgProps],
    );

    const bannerInlineStyles = useCallback((banner: IBannerResponse) => {
        if (banner.color) {
            return {
                border: `2px solid ${banner.color}`,
                backgroundColor: banner.color,
            };
        }

        return undefined;
    }, []);

    const renderOverlay = useCallback(
        (banner: IBannerResponse) => {
            const label = banner.translations[0].label;
            const text = banner.translations[0].text;
            const title = banner.translations[0].title;
            return (
                <>
                    <span
                        className="banner-item-label"
                        style={banner.color ? { backgroundColor: banner.color } : undefined}>
                        {title}
                    </span>
                    <div className={styles.bannerInfo}>
                        {!!banner.provider && (
                            <Img
                                source={`${ASSETS_URL}/common/casino/compact/${
                                    PROVIDERS_LOGO_MAP[
                                        banner.provider as keyof typeof PROVIDERS_LOGO_MAP
                                    ]
                                }.svg`}
                                alt={banner.provider}
                                width={35}
                                height={50}
                            />
                        )}

                        <p>
                            {label && <span>{label}</span>}
                            {text && <span>{text}</span>}
                        </p>
                    </div>
                </>
            );
        },
        [ASSETS_URL],
    );

    const playGameHandler = useCallback(
        (banner: IBannerResponse, forFun?: boolean) => {
            const bannerMedia = banner.translations[0];
            const href = bannerMedia.href;

            const isSlots = banner.href_behavior === HrefBehavior.GameSlot;
            const gameId = href ? `${href}-mobile` : "";

            if (forFun) {
                handlePlayForFun(gameId, isSlots);
            } else {
                handlePlayNow(gameId, isSlots);
            }
        },
        [handlePlayForFun, handlePlayNow],
    );

    const renderBanner = useCallback(
        (banner: IBannerResponse, shouldPreload: boolean) => {
            const bannerMedia = banner.translations[0];
            if (isLink(banner.href_behavior) && bannerMedia.href) {
                if (banner.href_behavior === HrefBehavior.Relative) {
                    const linkClickHandler = (e: SyntheticEvent) => {
                        if (!isUserLoggedIn && bannerMedia.href?.includes("forFun=false")) {
                            e.preventDefault();
                            playGameHandler(banner);
                        }
                    };
                    return (
                        <Link
                            onClick={linkClickHandler}
                            href={bannerMedia.href}
                            style={bannerInlineStyles(banner)}
                            className="banner-item">
                            {renderMedia(banner, shouldPreload)}
                        </Link>
                    );
                } else if (banner.href_behavior === HrefBehavior.External) {
                    return (
                        <a
                            style={bannerInlineStyles(banner)}
                            target="_blank"
                            href={bannerMedia.href}
                            className="banner-item"
                            rel="noreferrer">
                            {renderMedia(banner, shouldPreload)}
                        </a>
                    );
                }
            } else if (isGame(banner.href_behavior)) {
                if (!!casinoGamePromo) {
                    return (
                        <div style={bannerInlineStyles(banner)} className="banner-item">
                            {renderMedia(banner, shouldPreload)}
                            <CasinoGamePlayButtons
                                handlePlayNow={() => playGameHandler(banner)}
                                handlePlayForFun={() =>
                                    casinoGamePromo?.forFunSupport
                                        ? playGameHandler(banner, true)
                                        : undefined
                                }
                                wrapperClassname={styles.casinoGamePlayButtons}
                            />
                        </div>
                    );
                }

                return (
                    <Button
                        type="button"
                        style={bannerInlineStyles(banner)}
                        onClick={() => playGameHandler(banner)}
                        className="banner-item">
                        {renderMedia(banner, shouldPreload)}
                    </Button>
                );
            }
            return (
                <div className="banner-item" style={bannerInlineStyles(banner)}>
                    {renderMedia(banner, shouldPreload)}
                </div>
            );
        },
        [
            bannerInlineStyles,
            casinoGamePromo,
            isGame,
            isLink,
            isUserLoggedIn,
            playGameHandler,
            renderMedia,
        ],
    );

    return (
        <section className={classNames(styles.bannerSlider, wrapperClassName)}>
            <div>
                {!!banners.length && (
                    <SlickSlider settings={defaultSettings}>
                        {banners.map((banner, index) => {
                            const shouldPreload = index === 0;
                            return (
                                <div
                                    className={styles.banner}
                                    key={banner.translations[0].bg_media?.url}>
                                    {renderBanner(banner, shouldPreload)}
                                    {showOverlay && renderOverlay(banner)}
                                </div>
                            );
                        })}
                    </SlickSlider>
                )}
            </div>
        </section>
    );
};

export default BannersSlider;
